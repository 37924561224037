import DateDiff from "date-diff";

if ("serviceWorker" in navigator) {
	navigator.serviceWorker.addEventListener("message", (event) => {
		console.log(event);
		if (event.data.msg == "update") {
			console.log(event.data.url);
		}
	});

	window.addEventListener("load", () => {
		navigator.serviceWorker.register("/sw.js", { scope: "/" });
	});
}

const loop = () => {
	document.querySelectorAll("h1").forEach((head) => {
		head.style.fontSize = "";

		if (!head.parentElement) return;

		const parentStyle = window.getComputedStyle(head.parentElement);
		const parentsParentStyle =
			head.parentElement.parentElement &&
			window.getComputedStyle(head.parentElement.parentElement);

		const parentBounds = parentStyle
			? {
					width:
						head.parentElement.clientWidth -
						parseFloat(parentStyle.paddingTop) -
						parseFloat(parentStyle.paddingBottom),
			  }
			: {
					width: head.parentElement.style.width,
			  };

		let parentsParentBounds;

		block: {
			if (!head.parentElement.parentElement) break block;

			parentsParentBounds =
				parentsParentStyle && parentStyle
					? {
							width:
								head.parentElement.parentElement.clientWidth -
								parseFloat(parentsParentStyle.paddingTop) -
								parseFloat(parentsParentStyle.paddingBottom) -
								parseFloat(parentStyle.paddingTop) -
								parseFloat(parentStyle.paddingBottom),
							height:
								head.parentElement.parentElement.clientHeight -
								parseFloat(parentsParentStyle.paddingLeft) -
								parseFloat(parentsParentStyle.paddingRight) -
								parseFloat(parentStyle.paddingLeft) -
								parseFloat(parentStyle.paddingRight),
					  }
					: {
							width: head.parentElement.parentElement.style.width,
							height: head.parentElement.parentElement.style.height,
					  };
		}

		let scale = 1;
		while (
			(head.offsetWidth > parentBounds.width ||
				(parentsParentBounds && head.offsetWidth > parentsParentBounds.width) ||
				(parentsParentBounds &&
					head.offsetHeight > parentsParentBounds.height)) &&
			scale > 0.2
		) {
			scale -= 0.01;
			head.style.fontSize = 3.5 * scale + "rem";
		}
	});
};

const getDateDiff = (date1: Date, date2: Date) => {
	const diff = new DateDiff(date1, date2);

	let temp;

	if ((temp = diff.years()) >= 1) {
		const floored = Math.floor(temp);
		if (floored === 1) return "1 year ago.";
		return Math.floor(temp) + " years ago.";
	}

	if ((temp = diff.months()) > 1) {
		const floored = Math.floor(temp);
		if (floored === 1) return "1 month ago.";
		return Math.floor(temp) + " months ago.";
	}

	if ((temp = diff.weeks()) > 1) {
		const floored = Math.floor(temp);
		if (floored === 1) return "1 week ago.";
		return Math.floor(temp) + " weeks ago.";
	}

	if ((temp = diff.days()) > 1) {
		const floored = Math.floor(temp);
		if (floored === 1) return "1 day ago.";
		return Math.floor(temp) + " days ago.";
	}

	if ((temp = diff.hours()) > 1) {
		const floored = Math.floor(temp);
		if (floored === 1) return "1 hour ago.";
		return Math.floor(temp) + " hours ago.";
	}

	if ((temp = diff.minutes()) > 1) {
		const floored = Math.floor(temp);
		if (floored === 1) return "1 minute ago.";
		return Math.floor(temp) + " minutes ago.";
	}

	const floored = Math.floor(diff.seconds());
	if (floored === 1) return "1 second ago.";
	return Math.floor(temp) + " seconds ago.";
};

window.addEventListener("resize", loop);
if (document.fonts && document.fonts.ready) {
	document.fonts.ready.then(loop);
}

const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

document.querySelectorAll("time.post-date").forEach((val) => {
	const el = val as HTMLElement;

	if (!el.dataset.time || el.dataset.parsed) return;

	const date = new Date(el.dataset.time);
	const now = new Date();

	const diffText = getDateDiff(now, date);

	el.innerText += ` - ${diffText}`;
	el.dataset.parsed = "true";
});

document.querySelectorAll("a.reversefootnote, a.footnote").forEach((val) => {
	const el = val as HTMLAnchorElement;

	el.addEventListener("click", (e) => {
		e.preventDefault();

		const targetEl = document.getElementById(el.hash.substr(1));

		const resEl = val.classList.contains("footnote")
			? targetEl
			: targetEl?.parentElement;

		if (!resEl) return;

		window.location.hash = resEl.id;

		if ("scrollBehavior" in document.documentElement.style) {
			window.scrollTo({
				top: resEl.offsetTop - 50,
				behavior: "auto",
			});
		} else {
			window.scrollTo(0, resEl.offsetTop - 50);
		}
	});
});

const videoPlay = () => {
	document.querySelectorAll("video.vid-gif").forEach((vid) => {
		const el = vid as HTMLVideoElement;
		if (mediaQuery.matches) {
			el.removeAttribute("autoplay");
			el.pause();
		} else {
			el.setAttribute("autoplay", "");
		}
	});
};

if (mediaQuery.addEventListener)
	mediaQuery.addEventListener("change", videoPlay);
else mediaQuery.addListener(videoPlay);

document.querySelectorAll("h2 > a").forEach((link) => {
	link.addEventListener("mouseover", () => {
		if (link.parentElement) link.parentElement.style.animation = "none";
	});
});

videoPlay();
loop();
